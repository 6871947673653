<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import type { TooltipProps } from './UtilsTooltip.props'

//If there is more than one theme a tooltip.scss file can be created to define all the themes
const props = withDefaults(defineProps<TooltipProps>(), {
  distance: 6,
  placement: 'bottom-start',
})

// Given VDropdown body is outside of our wrapping themed div, we need to apply theme styiling
const { currentWebsite } = useWebsite()
const { getBrandTheme } = await useBrandsSettings()
const theme = getBrandTheme(currentWebsite.value)

const openedPopper = ref(false)

const openPopper = (e: Event) => {
  openedPopper.value = !openedPopper.value
  if (props.stopEventPropagation) {
    e.preventDefault()
    e.stopPropagation()
  }
}

const popperPanelFocus = ref<HTMLElement | null>()
const { activate, deactivate } = useFocusTrap(popperPanelFocus, {
  allowOutsideClick: true,
})

const isVisibile = computed(() => popperPanelFocus.value?.checkVisibility())

watch(isVisibile, () => {
  setTimeout(() => {
    isVisibile.value && hasFocusableElement(popperPanelFocus.value!)
      ? activate()
      : deactivate()
  }, 100)
})
</script>
<template>
  <VDropdown
    :skidding="skidding"
    :distance="distance"
    :placement="placement"
    :popper-class="`theme-${theme} ${type ? `v-popper--type-${type}` : ''}`"
    :disabled="disabled"
  >
    <button
      v-close-popper="openedPopper"
      :aria-label="ariaLabel"
      aria-controls="popperContent"
      :aria-expanded="openedPopper"
      @click="(e: Event) => openPopper(e)"
    >
      <slot name="target" />
    </button>
    <template #popper>
      <div
        id="popperContent"
        ref="popperPanelFocus"
        class="v-popper__inner--container bg-background-tertiary text-text-primary p-4"
      >
        <slot name="content" />
      </div>
    </template>
  </VDropdown>
</template>
<style lang="scss">
.v-popper {
  &--theme-dropdown {
    .v-popper__inner {
      @apply rounded-lg border-0 shadow-none;
    }
    .v-popper__arrow-outer {
      border-color: var(--colorBackgroundTertiary);
    }
    .v-popper__arrow-inner {
      border-color: var(--colorBackgroundTertiary);
    }
  }

  &--type-hotspot-modal {
    background-color: white;
    margin: 16px 0;
    .v-popper__arrow-container {
      display: none !important;
    }
    .v-popper__inner {
      @apply rounded-none;
      border-radius: 0 !important;
    }
    .v-popper__inner--container {
      @apply bg-neutral-white text-neutral-black p-0;
    }
  }
}
</style>
